import { dateToString } from "src/stdlib/stdlib-date";

export const DashApiUrl = "/api/dashboard";
export const PushRegisterApiUrl = "/api/push-register";
export const PermissionsApiUrl = "/api/permissions";

export const SignOutHereApiUrl = "/api/sign-out";
export const SignOutEverywhereApiUrl = "/api/sign-out-everywhere";

export const SystemLogApiUrl = "/api/log";

export const UploadSinglePhotoApi: string = "/api/upload-single-photo";
export const DeleteSinglePhotoApi: (linkedGuid: string, key: string) => string = (linkedGuid, key) => `/api/single-photo/${linkedGuid}/${key}/delete`;
export const EditSinglePhotoMetaApi: (linkedGuid: string, key: string) => string = (linkedGuid, key) => `/api/single-photo/${linkedGuid}/${key}/meta`;

export const ListTransportServicesApi = "/api/transport-services";
export const NewTransportServiceApi = "/api/transport-services/new";
export const TransportServiceApi: (guid: string) => string = guid => `/api/transport-services/${guid}`;

export const ListCarSeatsApi = "/api/car-seats";
export const NewCarSeatApi = "/api/car-seats/new";
export const CarSeatApi: (guid: string) => string = guid => `/api/car-seats/${guid}`;

export const ListStaffUsersApiUrl = "/api/staff-users";
export const NewStaffUserApiUrl = "/api/staff-users/new";
export const StaffUserSettingsApi: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}`;
export const StaffUserSummaryApi: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}/summary`;
export const StaffUserResetPasswordApi: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}/resetpassword`;
export const StaffUserDeleteTotpApi: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}/removetotp`;
export const StaffUserClearWebPush: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}/clearwebpush`;
export const StaffUserForceSignout: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}/forcesignout`;
export const StaffUserSendTestWebPush: (userGuid: string) => string = userGuid => `/api/staff-users/${userGuid}/sendtestwebpush`;

export const ListPartnerUsersApiUrl: (partnerGuid: string) => string = partnerGuid => `/api/partner/${partnerGuid}/listusers`;
export const NewPartnerUserApiUrl: (partnerGuid: string) => string = partnerGuid => `/api/partner/${partnerGuid}/newuser`;
export const PartnerUserSettingsApi: (partnerGuid: string, userGuid: string) => string =
    (partnerGuid, userGuid) => `/api/partner/${partnerGuid}/users/${userGuid}`;
export const PartnerUserSummaryApi: (partnerGuid: string, userGuid: string) => string =
    (partnerGuid, userGuid) => `/api/partner/${partnerGuid}/users/${userGuid}/summary`;
export const PartnerUserResetPasswordApi: (partnerGuid: string, userGuid: string) => string =
    (partnerGuid, userGuid) => `/api/partner/${partnerGuid}/users/${userGuid}/resetpassword`;
export const PartnerUserDeleteTotpApi: (partnerGuid: string, userGuid: string) => string =
    (partnerGuid, userGuid) => `/api/partner/${partnerGuid}/users/${userGuid}/removetotp`;

export const ListLocationsApi = "/api/locations";
export const NewLocationApi = "/api/locations/new";
export const LocationApi: (guid: string) => string = guid => `/api/locations/${guid}`;

export const ListExtrasApi = "/api/extras";
export const NewExtraApi = "/api/extras/new";
export const ExtraApi: (guid: string) => string = guid => `/api/extras/${guid}`;

export const ListPartnersApi = "/api/partner";
export const NewPartnerApi = "/api/partner/new";
export const PartnerApi: (guid: string) => string = guid => `/api/partner/${guid}`;
export const PartnerAddPaymentApi: (guid: string) => string = guid => `/api/partner/${guid}/addpayment`;
export const PartnerAccountBalanceApi: (guid: string) => string = guid => `/api/partner/${guid}/accountbalance`;
export const PartnerQueryTransactionsApi: (guid: string) => string = guid => `/api/partner/${guid}/querytransactions`;
//export const PartnerPaymentHistoryApi: (guid: string) => string = guid => `/api/partner/${guid}/paymenthistory`;
export const PartnerInvoiceReportApi: (guid: string) => string = guid => `/api/partner/${guid}/invoicereport`;
export const ExportPartnerInvoiceReportCsvUrl: (props: { partnerGuid: string, date: Date }) => string = props =>
    `/export/partner-${props.partnerGuid}-invoice-${props.date.getFullYear()}-${props.date.getMonth() + 1}.xlsx`;


export const PartnerManagerAccountBalanceApi = "/api/actions/accountbalance";
export const PartnerManagerQueryTransactionsApi = "/api/actions/querytransactions";

export const ListBlockedDates = "/api/blocked-dates";
export const NewBlockedDateApi = "/api/blocked-dates/new";
export const BlockedDateApi: (date: string) => string = date => `/api/blocked-dates/${date}`;

export const FastTrackPriceApi = "/api/fasttrackprice";
export const AccountSettingsApi = "/api/account-settings";
//export const AccountPushReApi = "/api/account-settings";
export const HomepagePriceApi = "/api/homepageprice";
export const PostServiceConfigApi = "/api/postserviceconfig";

export const TaskResetAirportsApi = "/api/tasks/resetairports";
export const TaskUpdateFlightTimesApi = "/api/tasks/updateflighttimes";
//export const TaskUpdateFlightSchedulesApi = "/api/tasks/updateflightschedules";
export const TaskInitFlightSchedulesApi = "/api/tasks/initflightschedules";
export const TaskUpdateAviationNamesApi = "/api/tasks/updateaviationnames";
export const TaskUploadLocationsApi = "/api/tasks/uploadlocations";
export const TaskAssignDayNumbersApi = "/api/tasks/assigndaynumbers";
export const TaskExportLocationsApi = "/export/locations.csv";

export const EmptyCustomerApi = "/api/emptycustomer";
export const LoadCustomerApi: (guid: string) => string = guid => `/api/customer/${guid}`;
export const EditCustomerApi: (guid: string) => string = guid => `/api/customer/${guid}/edit`;

export const GroupDetailApi: (customerGuid: string, groupGuid: string) => string =
    (customer, group) => `/api/customer/${customer}/${group}/groupdetail`;
export const BookingDetailApi: (customerGuid: string, bookingGuid: string) => string =
    (customer, booking) => `/api/customer/${customer}/${booking}/bookingdetail`;
export const ChargeFeeApi: (customerGuid: string, bookingGuid: string) => string = (customer, booking) => `/api/customer/${customer}/${booking}/chargefee`;

export const AddFlightServiceApi: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/addflightservice`;
export const EditPointBookingApi: (customerGuid: string, bookingGuid: string) => string =
    (customer, booking) => `/api/customer/${customer}/${booking}/editpoint`;

export const EditFlightPaxApi: (customerGuid: string) => string = guid => "";
export const EditFlightPaxDetailsApi: (customerGuid: string, groupGuid: string) => string =
    (customer, group) => `/api/customer/${customer}/${group}/editpaxdetails`;

export const CancelFlightApi: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/cancelflight`;

export const QueryCustomersApi = "/api/actions/querycustomers";
export const QueryTransactionsApiUrl = "/api/actions/querytransactions";
export const QueryGratuitiesApiUrl = "/api/actions/querygratuities";
export const QueryDriverGratuitiesApiUrl = (props: { userGuid: string }) => `/api/actions/querydrivergratuities?userGuid=${props.userGuid}`;

export const DriverQueryGratuitiesApiUrl = "/api/actions/currentuserdrivergratuities";

export const EditCommentsApi: (bookingGuids: string) => string = (bookingGuids) => `/api/actions/bookingcomments?bookingGuids=${bookingGuids}`;

export const WeeklyFlightsCalendarApiUrl: (startDate: Date) => string = (startDate) => `/api/flights/weekly?startDate=${dateToString(startDate)}`;
export const DailyFlightsCalendarApiUrl: (startDate: Date) => string = (startDate) => `/api/flights/daily?startDate=${dateToString(startDate)}`;
//export const ArrivalFlightsListApiUrl: (date: string) => string = (date) => `/api/flights/arrivallist?date=${date}`;
//export const DepartureFlightsListApiUrl: (date: string) => string = (date) => `/api/flights/departurelist?date=${date}`;
// export const FlightsByBookingListApiUrl: (date: string, customerGuid: string, bookingGuid: string) => string =
//     (date, customerGuid, bookingGuid) => `/api/flights/listbybooking?date=${date}&customerGuid=${customerGuid}&bookingGuid=${bookingGuid}`;

export const PickerFlightsApiUrl: (date: string, direction: "arrival" | "departure") => string = (date, direction) =>
    `/api/flights/pickerflights?date=${date}&direction=${direction}`;


export const FastTrackDoorScanApi: (bookingIdentifier: string) => string = (id) => `/api/scan/doorscan?id=${id}`;
export const FastTrackDoorConfirmApi: (bookingIdentifier: string) => string = (id) => `/api/scan/doorconfirm?id=${id}`;
export const FastTrackBaggageScanApi: (bookingIdentifier: string) => string = (id) => `/api/scan/baggagescan?id=${id}`;
export const TransportStartScanApi: (bookingIdentifier: string) => string = (id) => `/api/scan/transportstart?id=${id}`;
export const TransportEndScanApi: (bookingIdentifier: string) => string = (id) => `/api/scan/transportend?id=${id}`;

export const SystemLedgerApiUrl = "/api/ledger";

export const DailyFastTrackArrivalsReportApiUrl: (props: { date: string }) => string = props => `/api/arrivals-${props.date}`;
export const DailyFastTrackDeparturesReportApiUrl: (props: { date: string }) => string = props => `/api/departures-${props.date}`;
export const DailyTransportReportApiUrl: (props: { date: string }) => string = props => `/api/transport-${props.date}`;
export const DailyWaitTimeReportApiUrl: (props: { date: string }) => string = props => `/api/waittimes-${props.date}`;

export const DriverTransportReportApiUrl: (props: { userGuid: string, date: string }) => string =
    props => `/api/dailydrivertransport-${props.date}?userGuid=${props.userGuid}`;
export const DriverWaitTimeReportApiUrl: (props: { userGuid: string, date: string }) => string =
    props => `/api/dailydriverwaittime-${props.date}?userGuid=${props.userGuid}`;

export const CurrentUserDailyTransportReportApiUrl: (props: { date: string }) => string = props => `/api/currentdrivertransport-${props.date}`;

export const DailyFastTrackArrivalsCsvUrl: (props: { date: string }) => string = props => `/export/arrivals-${props.date}.xlsx`;
export const DailyFastTrackDeparturesCsvUrl: (props: { date: string }) => string = props => `/export/departures-${props.date}.xlsx`;
export const DailyTransportCsvUrl: (props: { date: string }) => string = props => `/export/transport-${props.date}.xlsx`;

export const TicketPdfUrl: (customerGuid: string, groupGuid: string) => string = (customerGuid, groupGuid) => `/tickets/${customerGuid}/${groupGuid}.pdf`;

export const DateStatusCalendarApiUrl: (date: string) => string = date => `/api/datestatusmonth?date=${date}`;
export const DateStatusApiUrl: (date: string) => string = date => `/api/date-status/${date}`;

export const TransactionDetailApiUrl = (guid: string) => `/api/transactions/${guid}`;

export const ValidateAdvancedFlightsApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/validateadvancedflights`;
export const GetAdvancedFlightInfoApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/getadvancedflightinfo`;
export const ValidateAdvancedFastTrackApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/validateadvancedfasttrack`;
export const ValidateAdvancedTransportApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/validateadvancedtransport`;
export const ValidateAdvancedCustomerInfoApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/validateadvancedcustomerinfo`;

export const ConfirmAdvancedBookingApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/confirmadvancedbooking`;
export const MakeAdvancedBookingApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/makeadvancedbooking`;

export const ConfirmPrivateTransportApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/confirmprivatebooking`;
export const MakePrivateTransportApiUrl: (customerGuid: string) => string = (customer) => `/api/customer/${customer}/makeprivatebooking`;

export const ConfirmHourlyBookingApiUrl: (customerGuid: string) => string = customer => `/api/customer/${customer}/confirmhourlybooking`;
export const MakeHourlyBookingApiUrl: (customerGuid: string) => string = customer => `/api/customer/${customer}/makehourlybooking`;

export const ValidatePointCustomerInfoApiUrl: (customerGuid: string) => string = customer => `/api/customer/${customer}/validatepointcustomerinfo`;
export const ValidatePointTripsApiUrl: (customerGuid: string) => string = customer => `/api/customer/${customer}/validatepointtrips`;
export const ConfirmPointBookingApiUrl: (customerGuid: string) => string = customer => `/api/customer/${customer}/confirmpointbooking`;
export const MakePointBookingApiUrl: (customerGuid: string) => string = customer => `/api/customer/${customer}/makepointbooking`;


export const FtConcerigeDayApiUrl: (date: string) => string = date => `/api/actions/ftconcerigedashboard?date=${date}`;
export const FtConcerigeQueryApiUrl: (date: string) => string = date => `/api/actions/ftconcerigequery?date=${date}`;
export const DispatcherDayApiUrl: (date: string) => string = date => `/api/actions/dispatcherdashboard?date=${date}`;
export const DispatcherDriverApiUrl: (userGuid: string) => string = userGuid => `/api/actions/dispatcherdriver?userGuid=${userGuid}`;
export const DispatcherDriverStateApiUrl: (userGuid: string) => string = userGuid => `/api/actions/dispatcherdriverstate?userGuid=${userGuid}`;
export const AssignTripToDriverApiUrl = (tripGuid: string, driverGuid: string) => `/api/actions/assigntriptodriver?tripGuid=${tripGuid}&driverGuid=${driverGuid}`;
export const DispatcherRescheduleApi = "/api/actions/dispatcherreschedule";
export const DispatcherActionApi = "/api/actions/dispatcheraction";

export const FtConcerigeListCloseDayApiUrl: (date: string) => string = date => `/api/actions/FtConcerigeListCloseDay?date=${date}`;
export const FtConcerigeConfirmCloseDayApiUrl: (date: string) => string = date => `/api/actions/FtConcerigeConfirmCloseDay?date=${date}`;

export const FtArrivalScanQueryApiUrl = `/api/actions/ftarrivalquery`;
export const FtDepartureScanQueryApiUrl = `/api/actions/ftdeparturequery`;

export const FtArrivalLoadBookingApiUrl = "/api/actions/ftarrivalloadbooking";
export const FtArrivalCheckInApiUrl = (groupGuid: string) => `/api/actions/ftarrivalcheckin?groupGuid=${groupGuid}`;
export const FtArrivalConfirmApiUrl = (groupGuid: string) => `/api/actions/ftarrivalconfirm?groupGuid=${groupGuid}`;

export const FtDepartureLoadBookingApiUrl = "/api/actions/ftdepartureloadbooking";
export const FtDepartureCheckInApiUrl = (groupGuid: string) => `/api/actions/ftdeparturecheckin?groupGuid=${groupGuid}`;
export const FtDepartureConfirmApiUrl = (groupGuid: string) => `/api/actions/ftdepartureconfirm?groupGuid=${groupGuid}`;

export const FtDepartureResetLoadBookingApiUrl = "/api/actions/ftdepartureresetloadbooking";
export const FtDepartureResetCheckInApiUrl = (groupGuid: string) => `/api/actions/ftdepartureresetcheckin?groupGuid=${groupGuid}`;
export const FtDepartureResetScanQueryApiUrl = `/api/actions/ftdepartureresetquery`;
//export const FtDepartureResetConfirmApiUrl = (groupGuid: string) => `/api/actions/ftdepartureresetconfirm?groupGuid=${groupGuid}`;


export const StaffPurchaseApiUrl = "/api/actions/staffpurchase";
export const OnSiteDateChangeApiUrl = "/api/actions/onsitedatechange";

export const ConfirmFlightChangeApiUrl = "/api/actions/confirmflightchange";
export const DoFlightChangeApiUrl = "/api/actions/doflightchange";

export const ConfirmMergeCustomerApiUrl = "/api/actions/confirmmergecustomer";
export const DoMergeCustomerApiUrl = "/api/actions/domergecustomer";


export const ConfirmCancelBookingApiUrl = "/api/actions/confirmcancelbooking";
export const DoCancelBookingApiUrl = "/api/actions/docancelbooking";

export const ConfirmApproveWaitlistApiUrl = "/api/actions/confirmapprovewaitlist";
export const DoApproveWaitlistApiUrl = "/api/actions/doapprovewaitlist";

export const TransportChangeStateApiUrl = "/api/actions/transportchangestate";
export const ConfirmTransportChangeApiUrl = "/api/actions/confirmtransportchange";
export const DoTransportChangeApiUrl = "/api/actions/dotransportchange";

export const AddCarsStateApiUrl = "/api/actions/addcarsstate";
export const ConfirmAddCarsApiUrl = "/api/actions/confirmaddcars";
export const DoAddCarsApiUrl = "/api/actions/doaddcars";

export const FastTrackChangeStateApiUrl = "/api/actions/fasttrackchangestate";
export const ConfirmFastTrackChangeApiUrl = "/api/actions/confirmfasttrackchange";
export const DoFastTrackChangeApiUrl = "/api/actions/dofasttrackchange";

export const ConfirmResetChangeApiUrl = "/api/actions/confirmresetbooking";
export const DoResetChangeApiUrl = "/api/actions/doresetbooking";

export const ResetCustomerBirthdayApiUrl = (customerGuid: string) => `/api/actions/resetcustomerbirthday?customer=${customerGuid}`;

export const SendCustomerAccountReminderEmailApiUrl = (customerGuid: string) => `/api/actions/sendcustomerreminderemail?customer=${customerGuid}`;
export const SendBookingReminderEmailApiUrl = (customerGuid: string, groupGuid: string) =>
    `/api/actions/sendbookingreminderemail?customer=${customerGuid}&group=${groupGuid}`;

export const DriverDashAssignedTripsApiUrl = "/api/actions/driverassignedtrips";
//export const DriverDashCheckTicketApiUrl = (ticketNumber: string)=> `/api/actions/drivercheckticket?ticket=${ticketNumber}`;
export const DriverDashQueryApiUrl = (query: string) => `/api/actions/driverdashquery?query=${query}`;
export const DriverDashTripApiUrl = (tripGuid: string) => `/api/actions/drivertrip?tripGuid=${tripGuid}`;
export const DriverRescheduleTripApiUrl = "/api/actions/driverrescheduletrip";
export const DriverNoShowTripApiUrl = (tripGuid: string) => `/api/actions/drivernoshowtrip?tripGuid=${tripGuid}`;
export const DriverStartTripApiUrl = (tripGuid: string) => `/api/actions/driverstarttrip?tripGuid=${tripGuid}`;
export const DriverStartWaitApiUrl = (tripGuid: string) => `/api/actions/driverstartwait?tripGuid=${tripGuid}`;
export const DriverDepartPickupApiUrl = (tripGuid: string) => `/api/actions/driverdepartpickup?tripGuid=${tripGuid}`;
export const DriverCompleteTripApiUrl = (tripGuid: string) => `/api/actions/drivercompletetrip?tripGuid=${tripGuid}`;

export const FinancalReportOverviewApiUrl = "/api/financalreport/overview";
export const FinancalReportSummaryApiUrl = "/api/financalreport/summary";
export const FinancalReportFastTrackApiUrl = "/api/financalreport/fasttrack";
export const FinancalReportTransportApiUrl = "/api/financalreport/transport";
export const FinancalReportAirportTransportApiUrl = "/api/financalreport/airporttransport";

export const ConfirmBokunImportApiUrl = "/api/actions/confirmbokunimport";
export const DoBokunImportApiUrl = "/api/actions/dobokunimport";

export const ConfirmPnpImportApiUrl = "/api/actions/confirmpnpimport";
export const DoPnpImportApiUrl = "/api/actions/dopnpimport";

export const ListTopPendingImportsApiUrl = "/api/actions/listtoppendingimports";
export const GetPendingImportApiUrl = (guid: string) => `/api/actions/getpendingimport?guid=${guid}`;

export const DoImportApiUrl = (guid: string) => `/api/actions/dopendingimport?guid=${guid}`;
export const ConfirmImportApiUrl = (guid: string) => `/api/actions/confirmimport?guid=${guid}`;
export const QueryImportedPaymentsApiUrl = "/api/actions/queryimportedpayments";

export const StaffCrashedCartsApiUrl = "/api/actions/crashedcarts";